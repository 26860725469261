export class Io {
  constructor() {
    this.els = [...document.querySelectorAll('.js-io')];
    this.init();
  }

  init() {
    this.initIntersectionObserver();
    this.addEventListeners();
    this.onScroll();
  }

  addEventListeners() {
    this.header = document.querySelector('.header');

    document.addEventListener('scroll', () => { this.onScroll(); });
  }

  onScroll() {
    if (window.scrollY >= window.innerHeight / 2.5) {
      this.header.classList.add('blurry');
    } else {
      this.header.classList.remove('blurry');
    }
  }

  normalize(x, istart, istop, ostart, ostop) {
    return ostart + (ostop - ostart) * ((x - istart) / (istop - istart));
  }

  initIntersectionObserver() {
    this.observer = new IntersectionObserver(this.intersectionCallback.bind(this));

    this.els.forEach((el) => {
      this.observer.observe(el);
    });
  }

  intersectionCallback(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const wrapper = entry.target;
        const $image = wrapper.querySelector('img');
        $image.setAttribute('src', wrapper.dataset.src);
        $image.classList.remove('placeholder');
        $image.classList.add('photo--animate');
        this.observer.unobserve(wrapper);
      }
    });
  }
}
