import './fonts/libre-baskerville-v5-latin-regular.woff';
import './fonts/libre-baskerville-v5-latin-regular.woff2';

import './images/01.jpg';
import './images/02.jpg';
import './images/03.jpg';
import './images/04.jpg';
import './images/05.jpg';
import './images/06.jpg';
import './images/07.jpg';
import './images/08.jpg';
import './images/09.jpg';
import './images/10.jpg';
import './images/11.jpg';
import './images/12.jpg';
import './images/13.jpg';
import './images/14.jpg';
import './images/15.jpg';
import './images/16.jpg';
import './images/17.jpg';
import './images/18.jpg';
import './images/19.jpg';
import './images/20.jpg';
import './images/21.jpg';
import './images/22.jpg';
import './images/23.jpg';
import './images/24.jpg';
import './images/25.jpg';
import './images/26.jpg';
import './images/27.jpg';
import './images/28.jpg';
import './images/29.jpg';
import './images/30.jpg';
import './images/31.jpg';
import './images/32.jpg';
import './images/33.jpg';
import './images/34.jpg';
import './images/35.jpg';
import './images/36.jpg';
import './images/37.jpg';

import './index.html';
import './index.scss';
import './scripts/script.js';

require('offline-plugin/runtime').install();
